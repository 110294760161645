<template>
    <b-modal
        id="duplicatesDetails"
        ok-variant="primary"
        :ok-title="$t(locale.seeDetails)"
        ok-only
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        centered
        size="xl"
        @close="clearSelected"
        @hide="clearSelected"
        clickToClose
        :title="modalTitle"
    >
        <Loading v-if="showLoading" />

        <b-card-text
            v-if="disableList"
            class="d-flex flex-column justify-content-center"
            style="gap: 1rem"
        >
            <span v-for="(data, index) in modalObject" :key="index">
                <b-row>
                    <b-col cols="6">
                        <p class="mb-0" v-text="$t(data.label)" />
                    </b-col>
                    <b-col cols="6" class="d-flex" style="flex-wrap: wrap">
                        <template v-if="Array.isArray(data.value)">
                            <p
                                class="mb-0 mr-1"
                                v-for="(value, indexValue) in data.value"
                                :key="indexValue"
                            >
                                {{ value }}
                                <span> , </span>
                            </p>
                        </template>

                        <p v-else class="mb-0" v-text="data.value" />
                    </b-col>
                </b-row>

                <hr class="divider" />
            </span>
        </b-card-text>

        <template #modal-footer>
            <b-dropdown
                id="dropdown-4"
                v-ripple.400
                :text="$t(locale.todo)"
                variant="warning"
            >
                <b-dropdown-item
                    v-for="(option, index) in $t(locale.todoOptions)"
                    @click="checkDropdown(option.event)"
                    :key="index"
                >
                    {{ option.label }}
                </b-dropdown-item>
            </b-dropdown>

            <b-button
                :to="{name: 'duplicatesDetail', params: {id: uuid}}"
                variant="gradient-primary"
            >
                {{ $t(locale.seeDetails) }}
            </b-button>
        </template>
    </b-modal>
</template>

<script>
import {duplicatesMixins} from '@/mixins/duplicatesMixins';
export default {
    props: {
        uuid: {
            type: String,
        },
        duplicatesDetails: {
            type: Object,
        },
        modalTitle: {
            type: String,
        },
    },
    mixins: [duplicatesMixins],
    data() {
        return {
            showLoading: true,
            disableList: false,
            accidentmsg: 'Duplicates.accidentMsg',
        };
    },

    watch: {
        duplicatesDetails(value) {
            this.showLoading = true;
            this.disableList = false;

            this.showLoading = false;
            this.disableList = true;
            this.modalObject.push(
                {
                    label: 'Duplicates.duplicateField',
                    value: value.duplicates.replace(/[^a-zA-Z0-9, ]/g, ''),
                },

                {
                    label: 'Duplicates.numberMatching',
                    value: value.num_matching_fields,
                },
                {
                    label: 'Duplicates.matchingField',
                    value: value.matching_fields,
                },
                {
                    label: 'Duplicates.tableHead.transaction',
                    value: value.transaction_id,
                },
                {
                    label: 'Duplicates.tableHead.resource',
                    value: value.resource,
                }
            );
        },
    },

    methods: {
        clearSelected() {
            this.$emit('clearSelected');
            this.modalObject = [];

            this.showLoading = true;
            this.disableList = false;
        },
        checkDropdown(event) {
            if (event == 'handleGroup') {
                this.$emit('handleGroup', this.uuid);
            } else if (event == 'notDuplicated') {
                this.$emit('notDuplicated', this.uuid);
            } else this.$emit('resolve', this.uuid);
        },
    },
};
</script>

<style></style>
