<template>
    <b-modal
        ok-variant="primary"
        :ok-title="$t(locale.confirm)"
        @ok="update"
        id="modalHandleGroup"
        cancel-variant="outline-secondary"
        modal-class="modal-primary"
        centered
        clickToClose
    >
        <b-card-text
            class="d-flex flex-column justify-content-center"
            style="gap: 1.5rem"
        >
            <p
                class="mb-0 pb-0 text-center h2"
                v-text="duplicatedmsg[handleGroupTitle]"
            />

            <div
                v-for="(msg, index) in duplicatedmsg.text"
                :key="index"
                class="d-flex flex-column px-4"
            >
                <p
                    class="mb-0 pb-0 font-weight-normal"
                    v-text="msg[handleGroupTitle]"
                    style="font-size: 13px"
                />
                <ul class="ml-2 mt-1">
                    <li
                        v-for="(values, index) in msg.values"
                        :key="index"
                        v-text="values"
                    />
                </ul>
            </div>

            <div class="d-flex flex-column px-4" style="gap: 1rem">
                <p
                    class="mb-0 pb-0 font-weight-normal"
                    v-text="duplicatedmsg[originalText]"
                    style="font-size: 13px"
                />
                <p class="mb-0 pb-0 h4" v-text="$t(locale.handleConfirm)" />
            </div>
        </b-card-text>
    </b-modal>
</template>

<script>
import {duplicatesMixins} from '@/mixins/duplicatesMixins';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
export default {
    mixins: [duplicatesMixins],
    props: {
        duplicatedmsg: {
            type: Object,
        },

        uuid: {
            type: String,
        },
    },
    methods: {
        update() {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: this.$t('Message.wait'),
                    variant: 'warning',
                    text: this.$t('Duplicates.waitHandle'),
                    waiting: true,
                },
            });
            this.$useJwt
                .updateHandleGroup(this.uuid)
                .then(res => {
                    this.popupMsg(
                        this.$t('Message.Success'),
                        res.data[this.msg],
                        'CheckIcon',
                        'success'
                    );
                    this.$emit('firstInstall', 'emit');
                })
                .finally(() => this.$bvModal.hide('modalHandleGroup'));
        },
    },
};
</script>

<style></style>
